import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';

import {
  PRODUCT_OPTION_TYPES,
  PRICE_MODIFIER_TYPES,
} from '../constants';

export const getModifierPrice = (price, priceModifier, priceModifierType) => (
  priceModifierType === PRICE_MODIFIER_TYPES.ABSOLUTE
    ? priceModifier
    : (price * priceModifier) / 100);

export const getModifierFromChoices = (choices, price) => choices.reduce(
  (total, { default: checked, priceModifier: modifierPrice, priceModifierType: modifierType }) => (
    checked
      ? total + getModifierPrice(price, modifierPrice, modifierType)
      : total),
  0
);

const getProductPrice = (price, quantity = 1, options = []) => {
  const startedPrice = parseFloat(price);
  let finalPrice = startedPrice;

  if (isNaN(startedPrice)) return 0;

  if (isArray(options) && !isEmpty(options)) {
    finalPrice = options.reduce((acc, { type, choices, defaultChoice }) => {
      if (isEmpty(choices)) return acc;

      const byDefault = type !== PRODUCT_OPTION_TYPES.CHECKBOX
      && (!defaultChoice && !isNumber(defaultChoice))
        ? choices.findIndex((item) => item.default)
        : defaultChoice;

      if (type === PRODUCT_OPTION_TYPES.CHECKBOX) {
        return acc + getModifierFromChoices(choices, price);
      }

      if (!isEmpty(choices[byDefault])) {
        const {
          priceModifier: modifierPrice,
          priceModifierType: modifierType,
        } = choices[byDefault];

        return acc + getModifierPrice(price, modifierPrice, modifierType);
      }

      return acc;
    }, finalPrice);
  }

  const totalPrice = finalPrice * quantity;

  return totalPrice >= 0 ? totalPrice : 0;
};

export default getProductPrice;
