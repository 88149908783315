import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isElement from 'lodash/isElement';

import dom from '../../wrapper/DomWrapper';

import { checkDisabledSSL } from './utils';
import {
  SITE_SEAL_ENABLED_CLASS_NAME,
  SITE_SEAL_DISABLED_CLASS_NAME,
} from './constants';

class SSLSeal {
  constructor(elWrapper) {
    this.elWrapper = elWrapper;
  }

  init = () => {
    if (!isElement(this.elWrapper)) return;

    const { settings } = this.elWrapper.dataset;

    this.settings = !isNil(settings) ? JSON.parse(settings) : {};
    this.elImage = dom.getElement('.js-widget-image', this.elWrapper);

    const isDisabledSSL = checkDisabledSSL(this.elWrapper);

    if (isDisabledSSL) {
      this.destroyWidget();

      return;
    }

    this.initWidget();
  }

  initWidget = () => {
    dom.addClass(this.elWrapper, SITE_SEAL_ENABLED_CLASS_NAME);
    dom.showOpacity(this.elImage);

    const { actionUrl } = this.settings;
    const isValidActionUrl = isString(actionUrl) && !isEmpty(actionUrl);

    if (!isValidActionUrl) return;

    dom.on(this.elImage, 'click', (e) => {
      e.preventDefault();

      dom.window.open(actionUrl, 'NETSB');

      return false;
    });
  }

  destroyWidget = () => {
    dom.addClass(this.elWrapper, SITE_SEAL_DISABLED_CLASS_NAME);
    dom.hideOpacity(this.elImage);
  };
}

export default SSLSeal;
