export const DEFAULT_SELECTORS = {
  modalContainerId: 'bp_container',
  lightBoxImgId: 'lightbox__img',
  nextBottomId: 'lightbox__arrow_right',
  prevBottomId: 'lightbox__arrow_left',
  navBotContPrevId: 'lightbox__arrow_left_container',
  navBotContNextId: 'lightbox__arrow_right_container',
  svgBottomPrevId: 'lightbox__arrow_left_svg',
  svgBottomNextId: 'lightbox__arrow_right_svg',
};

export const LIGHT_BOX_TEMPLATE = ({
  modalContainerId,
  lightBoxImgId,
  navBotContPrevId,
  svgBottomPrevId,
  prevBottomId,
  navBotContNextId,
  svgBottomNextId,
  nextBottomId,
} = DEFAULT_SELECTORS) => `
        <div data-reactroot="" id="${modalContainerId}" class="lightbox">
        <div class="lightbox__wrap">
          <div class="lightbox__picture">
            <img class="lightbox__img lazy" id="${lightBoxImgId}" alt="" style="visibility: visible">
          </div>
        </div>
        <div>
          <i class="lightbox__close icon" id="lightbox__close">
            <svg height="100%" width="100%">
              <use xlink:href="#sp-cross_20"></use>
            </svg>
          </i>
          <i class="lightbox__arrow _left icon" id="${navBotContPrevId}" style="display: none">
            <svg height="100%" width="100%" id="${svgBottomPrevId}">
              <use xlink:href="#sp-arrow-next" id="${prevBottomId}"></use>
            </svg>
          </i>
          <i class="lightbox__arrow _right icon" id="${navBotContNextId}" style="display: none">
            <svg height="100%" width="100%" id="${svgBottomNextId}">
              <use xlink:href="#sp-arrow-next" id="${nextBottomId}"></use>
            </svg>
          </i>
        </div>
      </div>
      `;

export const NAVIGATIONS_BUTTONS_STYLES = {
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
};

export const HIDE_SCROLL_BODY_CLASS = 'hide-scroll';
export const FADE_OUT_ANIMATION_DURATION = 300;
