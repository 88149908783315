import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import dom from '../../../wrapper/DomWrapper';

/**
 * Util for check if href origin is equal to current page origin
 * @param {string} href
 * @return {boolean} isHrefHasSameOrigin
 */
export default (href) => {
  if (!isString(href) || isEmpty(href)) return false;

  try {
    const { origin: currentSourceOrigin } = dom.window.location;
    const currentPageUrl = new URL(currentSourceOrigin);
    const anchorUrl = new URL(href, currentPageUrl);
    const { origin: anchorSourceOrigin } = anchorUrl;

    return currentSourceOrigin === anchorSourceOrigin;
  } catch (e) {
    console.error(e);
  }
};
