import get from 'lodash/get';
import axios from 'axios';
import template from 'lodash/template';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import dom from '../../../wrapper/DomWrapper';
import { convertToTree } from '../../../../components/Blog/utils';
import { BANNER_HEIGHT_DICT, POST_FORMAT, POST_PAGE_WIDTH_DICT } from '../../../../components/Blog/Post/constants';
import { POST_IMAGE_SELECTOR, VIDEO_WIDGET_SELECTOR } from '../../../constants';
import {
  HERO_SELECTOR, COMMENTS_SELECTOR, NAVIGATION_SELECTOR, POSTSOCIAL_SELECTOR, SOCIALBUTTONS_SELECTOR,
} from './constants';

import Hero from './Hero';
import Comments from './Comments';
import Navigation from './Navigation';
import PostSocial from './PostSocial';
import getStateValue from '../../../helpers/getStateValue';
import SocialButtons from '../SocialButtons/SocialButtons';
import PostImage from '../PostImage';
import Video from '../../Video';
import lazyLoad from '../../LazyLoad';

const getSinglePost = async (blogId, postUrl, isTemplate) => {
  const pageRenderService = getStateValue('apiUrls.pageRenderService');
  const projectId = getStateValue('projectId', '');

  const queryParameter = isTemplate ? `?templateLink=${projectId}` : '';

  const { data: post } = await axios.get(`${pageRenderService}/blog/${blogId}/post/${postUrl}${queryParameter}`);

  return post;
};

class Post extends WidgetWrapper {
  init = async () => {
    let dataPost = null;
    let dataBlog = null;

    const postDomNode = dom.getElement(this.selector);
    const isTemplate = getStateValue('isTemplate');

    try {
      const dataBlogRaw = postDomNode.getAttribute('data-settings');

      dataBlog = JSON.parse(dataBlogRaw);

      const { blogId } = dataBlog.blog;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const pathnameArr = window.location.pathname.split('/').filter(Boolean);
      const params = Object.fromEntries(urlSearchParams.entries());
      const postUrl = process?.env?.IS_LOCAL ? get(params, 'postUrl', '') : pathnameArr[pathnameArr.length - 1];

      dataPost = await getSinglePost(blogId, postUrl, isTemplate);
    } catch (error) {
      console.warn(error);

      return null;
    }

    if (!dataPost) {
      return null;
    }

    const { blog, menu } = dataBlog;
    const settings = get(blog, 'settings', {});
    const postCommentsProvider = get(settings, 'postCommentsProvider', {});
    const disqusAccount = get(blog, 'disqusAccount');
    const { postPageSettings } = convertToTree(settings);
    const commentSettings = {
      postId: get(dataPost, ['postId']),
      postCommentsSortBy: get(dataPost, ['settings', 'postCommentsSortBy']),
      postCommentsPerPage: get(dataPost, ['settings', 'postCommentsPerPage']),
    };
    const { postPageFormat, postPageSocialSharing, postPageNavigation } = postPageSettings;
    const postPageComment = get(dataPost, ['settings', 'showPostComments'], false);
    const isShowSocialSharingButtons = get(dataPost, ['settings', 'showSocialSharingButtons'], postPageSocialSharing);

    const {
      previewImageUrl,
      author,
      title,
      datePublished,
      // prev is nextUrl & next is prevUrl
      prevUrl: nextUrl,
      nextUrl: prevUrl,
      settings: {
        showCoverPhoto,
      },
      content,
    } = dataPost;
    const finalPageFormat = postPageFormat === POST_FORMAT.HERO || showCoverPhoto
      ? POST_FORMAT.HERO
      : POST_FORMAT.STANDARD;
    const {
      postPageMeta,
      postPageAlign,
      postPageWidth,
      postPageMetaStyle,
      postPageBannerHeight,
    } = postPageSettings[finalPageFormat];

    const isStandardFormat = finalPageFormat === POST_FORMAT.STANDARD;
    const blogCategory = `blog-category-${POST_PAGE_WIDTH_DICT[postPageWidth]}`;
    const standardPostWrapper = isStandardFormat ? 'post-page__wrapper' : '';
    const standardGalleryWrap = isStandardFormat ? 'gallery-wrap-container' : '';
    const heroPostWrapper = !isStandardFormat ? 'post-page__wrapper' : '';
    const heroGalleryWrap = !isStandardFormat ? 'gallery-wrap-container' : '';

    const dataHero = {
      title,
      dateAdded: datePublished,
      author,
      bannerSrc: previewImageUrl,
      bannerHeight: BANNER_HEIGHT_DICT[postPageBannerHeight],
      meta: postPageMeta,
      metaStyle: postPageMetaStyle,
      align: postPageAlign,
      format: finalPageFormat,
      isStandardFormat,
    };
    const dataComments = {
      postId: commentSettings.postId,
      countPerPage: commentSettings.postCommentsPerPage,
      sortBy: commentSettings.postCommentsSortBy,
      postCommentsProvider,
      disqusAccount,
      postPageComment,
    };
    const dataNavigation = {
      prev: prevUrl,
      next: nextUrl,
      menu,
    };

    const heroWidget = new Hero(HERO_SELECTOR);
    const commentsWidget = new Comments(COMMENTS_SELECTOR);
    const navigationWidget = postPageNavigation ? new Navigation(NAVIGATION_SELECTOR) : null;
    const postSocialWidget = new PostSocial(POSTSOCIAL_SELECTOR);
    const socialButtonsWidget = new SocialButtons(SOCIALBUTTONS_SELECTOR);
    const postImageWidget = new PostImage(POST_IMAGE_SELECTOR);
    const videoWidget = new Video(VIDEO_WIDGET_SELECTOR);

    const heroContent = heroWidget.getContent(dataHero);
    const commentsContent = commentsWidget.getContent(dataComments);
    const postSocialContent = postSocialWidget.getContent(isShowSocialSharingButtons);
    const compiled = template(postDomNode.parentNode.innerHTML, {
      interpolate: /{{=([\s\S]+?)}}/g,
      evaluate: /{{([\s\S]+?)}}/g,
    });

    const parentPostDomNode = postDomNode.parentNode;

    parentPostDomNode.innerHTML = compiled({
      content,
      hero: heroContent,
      comments: commentsContent,
      blogCategory,
      standardPostWrapper,
      standardGalleryWrap,
      heroPostWrapper,
      heroGalleryWrap,
      socialButtons: postSocialContent,
    });

    if (postPageNavigation) {
      navigationWidget.init(dataNavigation);
    }

    socialButtonsWidget.init();
    postImageWidget.init();
    videoWidget.init();

    if (postPageComment) {
      commentsWidget.init(postCommentsProvider, postDomNode);
    }

    parentPostDomNode.childNodes[0].style.display = 'none';
    parentPostDomNode.childNodes[1].style.visibility = 'visible';

    setTimeout(() => lazyLoad(), 0);
  };
}

export default Post;
