import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import VideoFactory from './Factory';

class Video extends WidgetWrapper {
  init = (props) => {
    const isPostList = get(props, 'isPostList', false);

    this.elVideos = dom.getCollection(this.selector);

    if (isEmpty(this.elVideos)) return;

    const videoComponent = new VideoFactory();

    for (let i = 0; i < this.elVideos.length; i += 1) {
      const selector = this.elVideos[i];
      const video = videoComponent.init(selector);

      if (video) video.init({ isPostList });
    }
  };
}

export default Video;
