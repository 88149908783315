export const FORMAT_TYPES = {
  FEED: 'feed',
  GRID: 'grid',
  LIST: 'list',
};

export const PAGINATION_TYPE = {
  numeric: 'numeric',
  prevNext: 'prev-next',
  dots: 'dots',
  infinite: 'infinite',
};

export const LAYOUT_TYPE = {
  grid: 'grid',
  list: 'list',
  mosaic: 'mosaic',
  slider: 'slider',
};
