import isUndefined from 'lodash/isUndefined';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import socialButtons from '../../../../components/Blog/Post/components/PostSocial/constants';

const buttonSocialTemplate = (linkType, icon, openInNewWindow) => `
            <a rel="noopener noreferrer" class="post-page__social-${icon} post-page__social-link"
             data-widget-in-window="${Boolean(isUndefined(openInNewWindow) ? true : openInNewWindow)}"
             data-widget-link-type="${linkType}" itemprop="url">
                <i class="icon post-page__social-icon" data-style="{}">
                    <svg data-style="{}" data-type="${icon}">
                         <use xlink:href="#templates/_global/social/${icon}"></use>
                    </svg>
                </i>
            </a>`;

const buttonContainerTemplate = (buttons) => `<div
    class="js-widget post-page__social"
    data-widget="blogSocialButton"
  >
    <div
      class="post-page__social-wrapper"
      itemProp="sharedContent"
      itemScope
      itemType="https://schema.org/WebPage"
    >
      ${buttons}
    </div>
  </div>`;

class PostSocial extends WidgetWrapper {
  getContent = (isShowSocialSharingButtons) => {
    if (!isShowSocialSharingButtons) {
      return '';
    }

    const assembledSocialButtons = socialButtons.reduce((acc, { type, icon, openInNewWindow }) => `${acc} ${buttonSocialTemplate(type, icon, openInNewWindow)}`, '');

    return buttonContainerTemplate(assembledSocialButtons);
  };
}

export default PostSocial;
