import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { isFullUrl } from './urlInfo';

import { FB } from '../../constants';
import {
  DEFAULT_IMAGE, FILE_DOWNLOAD_ATTR, NO_SRC, STATIC_MARKERS,
} from './constants';

/**
 * Function for get home page url
 * @return {string}
 */
export function getHomePage(menu) {
  if (!menu) return '';

  const items = menu.list.concat(menu.unlinked, menu.hidden || []);
  let homepage = items[0].tag;

  items.forEach((el) => {
    if (el.homepage) {
      homepage = el.tag;
    }
  });

  return homepage;
}

/**
 * Function for get related path
 * @param {string} link
 * @return {string}
 */
const getRelatedPath = (link) => {
  if (!link || !isString(link)) return link;

  const linkWithoutDoubleSlashes = link.replace(/([^:]\/)\/+/g, '$1');

  return linkWithoutDoubleSlashes[0] === '/'
    ? linkWithoutDoubleSlashes.substring(1)
    : linkWithoutDoubleSlashes;
};

/**
 * Returns link with trailing slash for anchors to avoid wrong Safari 301 redirects
 * @param {string} link
 * @param {string} anchor
 * @return {string}
 */
export function withAnchor(link, anchor) {
  const baseUrl = link || '';

  if (!anchor) return baseUrl;

  const slash = baseUrl && baseUrl[baseUrl.length - 1] !== '/' ? '/' : '';
  const sign = anchor && !anchor.startsWith('#') ? '#' : '';

  return `${baseUrl}${slash}${sign}${anchor}`;
}

/**
 * Function for create correct link with hash
 * @param {Array} menuItems
 * @param {string} hash
 * @param {string} anchor
 * @param {string} pageId
 * @return {string}
 */
function pageHashToLink(menuItems, hash, anchor, pageId) {
  let activeTag = null;

  const iterateMenu = (menu, path) => {
    if (Array.isArray(menu) && menu.length) {
      menu.forEach((menuItem) => {
        if (menuItem.hash === hash) {
          activeTag = menuItem.homepage ? '/' : `${path}${menuItem.tag}`;
        } else if (menuItem.children) {
          const isParentHome = menuItem.homepage && menuItem.children.some(
            (item) => item.hash === hash
          );
          const parentPath = `${path}${isParentHome
            ? ''
            : `${menuItem.tag}/`}`;

          iterateMenu(menuItem.children, parentPath);
        }
      });
    } else if (isPlainObject(menu)) {
      Object.values(menu).forEach((item) => iterateMenu(item, path));
    }
  };

  if (pageId === hash) {
    activeTag = '';
  } else {
    iterateMenu(menuItems, '/');
  }

  const link = !activeTag && !isString(activeTag) ? hash : activeTag;

  return withAnchor(link, anchor) || undefined;
}

/**
 * Function for create correct link with hash
 * @param {Object} data
 * @param {string} data.href
 * @param {string} data.anchor
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @return {string}
 */
function getHref({
  href,
  anchor,
  menu,
  pageId,
}) {
  const allLinks = menu && (menu.list || []).concat(menu.unlinked, menu.hidden || []);
  const sectionAnchor = anchor && `#s-${anchor}`;

  return pageHashToLink(allLinks, href, sectionAnchor, pageId);
}

/**
 * Function for normalise facebook link
 * @param {string} href
 * @return {string}
 */
export function normalizeFBhref(href = '') {
  if (href && !href.includes('http')) {
    if (href.indexOf('facebook.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = `https://${href}`;
    } else if (href.indexOf('fb.com') === 0) {
      // eslint-disable-next-line no-param-reassign
      href = href.replace('fb.com', FB);
    } else {
      // eslint-disable-next-line no-param-reassign
      href = `${FB}/${href}`;
    }
  }

  return href.slice(-1) === '/' ? href.slice(0, -1) : href;
}

/**
 * Function for filter digits only
 * @param {string} value
 * @return {string}
 */
export const getOnlyDigits = (value) => {
  if (!isString(value)) return '';

  return value.replace(/\D/g, '');
};

/**
 * Function for normalise link
 * @param {{}} data
 * @param {string} data.type
 * @param {string} data.href
 * @param {string} data.anchor
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @return {string}
 */
export function getLink({
  type,
  href,
  anchor,
  menu,
  pageId,
}) {
  let link = (href || '').trim();

  if (link && type) {
    switch (type.toLowerCase()) {
      case 'anchor':
      case 'page':
        link = getHref({
          href: link,
          anchor,
          menu,
          pageId,
        });

        break;
      case 'email':
        link = link.startsWith('mailto:') ? link : `mailto:${link}`;

        break;
      case 'phone':
        link = `tel:${link.includes('+') ? '+' : ''}${getOnlyDigits(link)}`;

        break;
      case 'url':
        if (!isFullUrl(link) && link !== '/') link = `http://${link}`;

        break;
      default:
        break;
    }
  }

  return link || undefined;
}

/**
 * Function for convert link to download link
 * @param {string} link
 * @return {string}
 */
export const convertLinkToDownloadType = (link) => {
  if (!link || !isString(link) || link.includes(FILE_DOWNLOAD_ATTR)) return link;

  return link + FILE_DOWNLOAD_ATTR;
};

/**
 * Function for extract filename
 * @param {string} link
 * @return {string}
 */
export const extractNameFromFileLink = (link) => {
  if (!link || !isString(link) || !link.length) return link;

  const fileName = link.split('/').pop();
  const downloadAttrIndex = fileName.indexOf(FILE_DOWNLOAD_ATTR);

  return downloadAttrIndex >= 0
    ? fileName.substring(0, downloadAttrIndex)
    : fileName;
};

/**
 * Function for check if static link
 * @param {string} link
 * @return {boolean}
 */
const checkStatic = (link) => {
  if (!link || !isString(link)) return false;

  const relatedPath = getRelatedPath(link);

  return STATIC_MARKERS.some((marker) => relatedPath.startsWith(marker));
};

/**
 * Function for check for external link
 * @param {string} link
 * @return {boolean}
 */
const checkExternal = (link) => {
  if (!link || !isString(link)) return false;

  return link.startsWith('http');
};

/**
 * Function for convert link to correct
 * @param {string} src
 * @param {Object} urlPrefixes
 * @param {Object} staticData
 * @return {string}
 */
export const convertSrcToCorrect = (src = '', urlPrefixes = {}, staticData = {}) => {
  if (!src || !isString(src)) return src;

  const { static: staticApi, fileStorageService } = urlPrefixes;
  const { isStaticPublished = false, staticDir = '' } = staticData;

  if (src === NO_SRC) return `${staticApi}/${DEFAULT_IMAGE}`;

  const fromFileStorage = src.startsWith(fileStorageService);
  const isExternal = checkExternal(src);

  if (isExternal && (!fromFileStorage || (fromFileStorage && !isStaticPublished))) return src;

  if (isStaticPublished) {
    const clearSrc = fromFileStorage ? src.replace(fileStorageService, '') : src;
    const pureSrc = clearSrc.startsWith('/') ? clearSrc.substring(1) : clearSrc;
    const pureStaticDir = staticDir.startsWith('/') ? staticDir.substring(1) : staticDir;

    if (pureSrc.startsWith(pureStaticDir)) return `/${pureSrc}`;

    return `/${pureStaticDir}/${pureSrc}`;
  }

  if (isEmpty(urlPrefixes)) return src;
  if (checkStatic(src)) return `${staticApi}/${getRelatedPath(src)}`;

  return `${fileStorageService}/${getRelatedPath(src)}`;
};

/**
 * Function for get link props
 * @param {Object} data
 * @param {string} data.link
 * @param {string} data.linkType
 * @param {Object} data.urlPrefixes
 * @param {Object} data.staticData
 * @param {{}} data.menu
 * @param {string} data.pageId
 * @param {string} anchor
 * @return {Object}
 */
export const getLinkProps = ({
  link,
  linkType,
  urlPrefixes = {},
  staticData = {},
  menu,
  pageId,
  anchor,
}) => {
  if (!link || !isString(link)) return { href: null };

  const href = getLink({
    type: linkType,
    href: link,
    anchor,
    menu,
    pageId,
  });

  if (isString(linkType) && linkType.toLowerCase() !== 'file') return { href };

  const fileLink = convertSrcToCorrect(href, urlPrefixes, staticData);

  return {
    href: convertLinkToDownloadType(fileLink),
    download: extractNameFromFileLink(fileLink),
  };
};
