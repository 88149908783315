import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import SSLSeal from './SSLSeal';

class SSLSealFactory extends WidgetWrapper {
  init = () => {
    const widgets = dom.getCollection(this.selector);

    [...widgets].forEach((widget) => {
      const instance = new SSLSeal(widget);

      instance.init();
    });
  }
}

export default SSLSealFactory;
