export const PAGE_TYPE_FOLDER = 'folder';
export const PAGE_TYPE_GROUP = 'group';
export const PAGE_TYPE_LINK = 'link';
export const PAGE_TYPE_PAGE = 'page';

export const PAGE_TYPE_BLOG = 'blog';
export const PAGE_TYPE_BLOG_POST = 'blogPost';

export const PAGE_TYPE_CATALOG = 'ecommerce';
export const PAGE_TYPE_CART = 'ecommerceCart';
export const PAGE_TYPE_SHIPPING = 'ecommerceShipping';
export const PAGE_TYPE_PRODUCT = 'ecommerceProduct';

export const PAGE_TYPE_COOKIE_POLICY = 'cookiesPolicy';

const ALL_TYPES_MAP = {
  FOLDER: PAGE_TYPE_FOLDER,
  GROUP: PAGE_TYPE_GROUP,
  LINK: PAGE_TYPE_LINK,
  PAGE: PAGE_TYPE_PAGE,

  BLOG: PAGE_TYPE_BLOG,
  BLOG_POST: PAGE_TYPE_BLOG_POST,

  CATALOG: PAGE_TYPE_CATALOG,
  CART: PAGE_TYPE_CART,
  SHIPPING: PAGE_TYPE_SHIPPING,
  PRODUCT: PAGE_TYPE_PRODUCT,

  COOKIE_POLICY: PAGE_TYPE_COOKIE_POLICY,
};

export default ALL_TYPES_MAP;
