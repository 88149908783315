import set from 'lodash/set';

// eslint-disable-next-line import/prefer-default-export
export const convertToTree = (data = {}) => Object
  .keys(data)
  .reduce((acc, curr) => set(acc, curr, data[curr]), {});

export const checkIsContentCorrect = (content) => {
  if (!content) return true;

  try {
    JSON.parse(content);
    return true;
  } catch (error) {
    return false;
  }
};
