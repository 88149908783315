import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import dom from '../../../wrapper/DomWrapper';
import { getPostFullUrl } from '../../../../components/Blog/Post/components/PostNavigation/utils';

class Navigation extends WidgetWrapper {
  init = (data) => {
    const {
      prev,
      next,
      menu,
    } = data;

    const navigationDomNode = dom.getElement(this.selector);
    const btnPrevDomNode = navigationDomNode.querySelector('.btn-navigation__prev');
    const btnNextDomNode = navigationDomNode.querySelector('.btn-navigation__next');

    if (btnPrevDomNode && prev) {
      btnPrevDomNode.setAttribute('href', getPostFullUrl(prev, menu));
      btnPrevDomNode.style.visibility = 'visible';
    }
    if (btnNextDomNode && next) {
      btnNextDomNode.setAttribute('href', getPostFullUrl(next, menu));
      btnNextDomNode.style.visibility = 'visible';
    }
  };
}

export default Navigation;
