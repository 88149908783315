import isValid from 'date-fns/isValid';

export const VALIDATORS = {
  required: (value) => /\S{1,}/.test(value),
  ga: (value) => /^$|^(ua|yt|mo)-\d{4,9}-\d{1,4}$/i.test(value),
  gtm: (value) => /^$|^(GTM)-[A-Z0-9]{0,10}/i.test(value),
  latin: (value) => /^[a-z0-9_-]{1,}(\s[a-z0-9_-]{1,}|){1,29}/i.test(value),
  noSpace: (value) => /^[^\s]+$/.test(value),
  // eslint-disable-next-line no-control-regex,max-len
  email: (value) => /(^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$)|(^\s*$)/i.test(
    value
  ),
  password: (value) => /^\S{6,32}$/.test(value),
  // eslint-disable-next-line max-len
  url: (value) => /(^((?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S)?@)?|\S)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$)|(^\s*$)/i.test(
    value
  ),
  // eslint-disable-next-line max-len
  domainUrl: (value) => /(http(s)?:makeFieldList\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    value
  ),
  number: (value) => /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:(\.|,)\d+)?$/.test(value),
  digits: (value) => /^\d+$/.test(value),
  // eslint-disable-next-line max-len
  phone: (value) => /^(\+[ ]?)?([0-9]+[ ]?(([/]|[-])[ ]?)?)?(\([ ]?([0-9]+([ ]?(([-]|[/])[ ]?[0-9]+)*)?)+[ ]?\))?[ ]?((([/]|[-])[ ]?)?[0-9]+[ ]?)+$/.test(
    value
  ),
  zip: (value) => /(^[a-z0-9]{1,7}(?:[-\s][a-z0-9]{1,5})?$)|(^\s*$)/i.test(value),
  // eslint-disable-next-line max-len
  username: (value) => /((^[a-zA-Zа-яА-Я\u0080-\u024F]{1,})+(([a-zA-Zа-яА-Я\u0080-\u024F.\s',_-]+)|(\s[a-zA-Zа-яА-Я\u0080-\u024F.\s',_-]{1,}){1,29}))|(^\s*$)/i.test(
    value
  ),
  // eslint-disable-next-line max-len
  firstname: (value) => /((^[a-zA-Zа-яА-Я\u0080-\u024F]{1,})+(([a-zA-Zа-яА-Я\u0080-\u024F.\s',_-]+)|(\s[a-zA-Zа-яА-Я\u0080-\u024F.\s',_-]{1,}){1,29}))|(^\s*$)/i.test(
    value
  ),
  lastname: (value) => /(^[A-z]+[A-z-_\s]*$)?/gi.test(value),
  name: (value) => /(^([a-zA-Z\u0080-\u024F'-.](?:\s*[a-zA-Z\u0080-\u024F'-.])*){2,200}$)|(^\s*$)/.test(
    value
  ),
  sitename: (value) => /((^[a-zA-Z0-9])+(([a-zA-Z0-9.',_-])|(\s[a-zA-Z0-9.',_-])){1,}$)/.test(value),
  logoname: (value) => /^[a-zA-Z0-9\u0080-\u024F!№"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]{1,63}$/.test(
    value
  ),
  city: (value) => /(^([a-zA-Z\u0080-\u024F]+\s*(?:. |-| |')\s*)*[a-zA-Z\u0080-\u024F\s]*$)|(^\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  address: (value) => /(^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9\u0080-\u024F ,.-/]*[a-zA-Z0-9\u0080-\u024F ]$)|(^\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  youtubeId: (value) => /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
    value
  ),
  date: (value) => value === '' || isValid(new Date(value)),
  state: (value) => /(^[a-zA-Z]([a-zA-Z0-9\u0080-\u024F.\s',_-]|[a-zA-Z0-9\u0080-\u024F\s]+)+)|(^\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipaddress: (value) => /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipv4: (value) => /(^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)/.test(
    value
  ),
  // eslint-disable-next-line max-len
  ipv6: (value) => /(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/.test(
    value
  ),
};

export const FIELDS_TYPE = {
  TEXTAREA: 'textarea',
  INPUT: 'input',
  SELECT: 'select',
};

export const SELECT_ITEM_TEMPLATE = (classNames, data) => (`
    <div
      class="${classNames.item} ${data.highlighted
    ? classNames.highlightedState
    : classNames.itemSelectable}
      ${data.placeholder ? classNames.placeholder : ''}"
      data-item data-id="${data.id}"
      data-value="${data.value}"
      ${data.active ? 'aria-selected="true"' : ''}
      ${data.disabled ? 'aria-disabled="true"' : ''}
    >
        ${data.label}
    </div>
`);

export const SELECT_CHOICE_TEMPLATE = (classNames, data) => (`
     <div
        ${data.placeholder ? 'style="display: none;"' : ''}
        class="${classNames.item} ${classNames.itemChoice} ${data.disabled
    ? classNames.itemDisabled
    : classNames.itemSelectable}"
        data-select-text=""
        data-choice ${data.disabled
    ? 'data-choice-disabled aria-disabled="true"'
    : 'data-choice-selectable'}
        data-id="${data.id}"
        data-value="${data.value}"
        ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
     >
       ${data.label}
     </div>
`);

export const SELECT_CLASS_NAMES = {
  containerOuter: 'Select',
  containerInner: 'Select-control',
  input: 'choices__input',
  inputCloned: 'choices__input--cloned',
  listItems: 'choices__list--multiple',
  listSingle: 'Select-multi-value-wrapper',
  listDropdown: 'Select-menu-outer',
  item: 'Select-value',
  itemSelectable: 'choices__item--selectable',
  itemDisabled: 'choices__item--disabled',
  itemChoice: 'Select-option',
  group: 'choices__group',
  groupHeading: 'choices__heading',
  button: 'choices__button',
  activeState: 'is-active',
  focusState: 'is-focused',
  openState: 'is-open',
  disabledState: 'is-disabled',
  highlightedState: 'is-highlighted',
  selectedState: 'is-selected',
  flippedState: 'is-flipped',
};

export const SELECT_CONFIG = {
  searchEnabled: false,
  shouldSort: false,
  itemSelectText: '',
  placeholder: true,
  classNames: SELECT_CLASS_NAMES,
  callbackOnCreateTemplates: (template) => ({
    item: (classNames, data) => template(SELECT_ITEM_TEMPLATE(classNames, data)),
    choice: (classNames, data) => template(SELECT_CHOICE_TEMPLATE(classNames, data)),
  }),
};

export const INPUT_WRAPPER_CLASS = 'input-wrap';
export const INPUT_WRAPPER_ERROR_CLASS = 'input-wrap_error';
export const INPUT_TEXT_ERROR_CLASS = 'input-error-text';
export const INPUT_ROW_CLASS = 'form__row';
export const INPUT_DESCRIPTION_SELECTOR = '.input-description';
export const INPUT_ERROR_ACTIVE_CLASS = `${INPUT_TEXT_ERROR_CLASS} input-error-text_visible`;
export const ONE_COLUMN_CLASS = 'row__item_desktop-2';

export const FORM_MESSAGE_CLASS = 'sent';
export const FORM_SPINNER_CLASS = 'spinner';

export const MESSAGE_TIMEOUT = 2000;

export const CAPTCHA_ERROR_CLASS = 'g-recaptcha--error';
export const CAPTCHA_ERROR_TYPES = {
  CLASS_NAME: 'className',
  TEXT: 'text',
};
export const CAPTCHA_ERROR_ACTIONS = {
  SHOW: 'show',
  HIDE: 'hide',
};
