export default [
  {
    type: 'fb',
    icon: 'facebook',
  },
  {
    type: 'twitter',
    icon: 'twitter',
  },
  {
    type: 'email',
    openInNewWindow: false,
    icon: 'email',
  },
];
