import isEmpty from 'lodash/isEmpty';
import Macy from 'macy';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import LightBox from '../Lightbox';
import dom from '../../wrapper/DomWrapper';

import { LIGHT_BOX_SELECTOR, GALLERY_CLONED } from './constants';
import LAYOUT_TYPE from '../../../components/Gallery/constants';
import { DEVICE_SIZES } from '../../../device';

class Gallery extends WidgetWrapper {
  init = () => {
    const elGalleryWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elGalleryWidgetList)) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);
    this.device = null;

    elGalleryWidgetList.forEach((gallery = {}, index) => {
      const {
        widgetId = null,
        settings,
      } = gallery.dataset;

      if (widgetId && settings) {
        const isCloned = !!gallery.closest('.slick-cloned');
        const uniqueWidgetId = isCloned ? `${widgetId}-${GALLERY_CLONED}-${index}` : widgetId;
        const parsedSettings = JSON.parse(settings);
        const { layoutType } = parsedSettings;

        if (isCloned) gallery.classList.add(GALLERY_CLONED, `${GALLERY_CLONED}-${index}`);

        this.lightBox.galleryModalData[uniqueWidgetId] = parsedSettings;
        this.connectGalleryToLightBox(uniqueWidgetId);
        if (layoutType === LAYOUT_TYPE.masonry) this.initMasonry(gallery, parsedSettings);
      }
    });
  };

  connectGalleryToLightBox = (galleryId) => {
    const {
      navButtonsAttributes,
      modalsParams,
    } = this.lightBox.getLightBoxAttributes();

    const galleryToConnect = galleryId
      ? { [galleryId]: this.lightBox.galleryModalData[galleryId] }
      : this.lightBox.galleryModalData;

    this.lightBox.addLightBoxToGalleryPhotos(
      galleryToConnect,
      navButtonsAttributes,
      modalsParams
    );
  };

  initMasonry = (gallery, parsedSettings) => {
    const { amountInRow, amountInRowMobile } = parsedSettings || {};

    const elGalleryWrap = gallery.children[0];
    const instanceMacy = this.createMasonryLayout(
      elGalleryWrap, amountInRow, amountInRowMobile
    );

    elGalleryWrap.children.forEach((elGalleryItem) => {
      const elImg = dom.getElement('img', elGalleryItem);

      dom.hideOpacity(elGalleryItem);

      elImg.addEventListener('load', () => {
        instanceMacy.reInit();
        dom.showOpacity(elGalleryItem);
      });
    });
  }

  createMasonryLayout = (el, amountInRow, amountInRowMobile) => {
    const breakAt = amountInRowMobile.reduce((acc, curr) => {
      const { count, device } = curr;
      const [, maxQuery] = DEVICE_SIZES[device];

      if (!maxQuery) return acc;

      return {
        ...acc,
        [maxQuery]: Math.ceil(count),
      };
    }, {});

    return new Macy({
      container: el,
      trueOrder: true,
      columns: Math.ceil(amountInRow),
      breakAt,
      margin: null,
    });
  }
}

export default Gallery;
