import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

/**
 * Util for remove first and last slash from pathName string
 * @param {string} pathName
 * @return {string} pathName
 */
export default (pathName) => {
  if (!isString(pathName) || isEmpty(pathName)) return pathName;

  return pathName
    .replace(/\//, '') // remove first slash
    .replace(/\/+$/, ''); // remove last slash
};
