import format from 'date-fns/format';
import { addGridItemImages } from './utils';
import PostImage from '../../Blog/PostImage/PostImage';
import { POST_IMAGE_SELECTOR } from '../../../constants';

const preRender = (item) => ({
  ...item,
  // for Safari replace(/ /g, 'T')
  datePublished: format(new Date(item.datePublished.replace(/ /g, 'T')), 'dd MMM yyyy'),
});

const postRender = (element, data) => {
  addGridItemImages(element, data);

  return element;
};

const didMount = (container) => {
  (new PostImage(POST_IMAGE_SELECTOR)).init(container);
};

export default {
  preRender,
  postRender,
  didMount,
};
