export const POST_PAGE_WIDTH_DICT = {
  narrow: 8,
  standard: 10,
  wide: 12,
};

export const BANNER_HEIGHT_DICT = {
  640: 'tall',
  410: 'medium',
  310: 'short',
};

export const POST_FORMAT = {
  HERO: 'hero',
  STANDARD: 'standard',
};
