import isEmpty from 'lodash/isEmpty';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import LightBox from '../../Lightbox/LightBox';
import dom from '../../../wrapper/DomWrapper';

import attachErrorHandler from './PostImageErrorHandler';
import { LIGHT_BOX_SELECTOR } from '../../Photo/constants';
import lazyLoad from '../../LazyLoad';

class PostImage extends WidgetWrapper {
  init = (parent = dom.document) => {
    const elPostImagesList = dom.getCollection(this.selector, parent);

    if (isEmpty(elPostImagesList)) return;

    elPostImagesList.forEach((photo = {}) => {
      const { widgetId = null, settings } = photo.dataset;

      if (widgetId && settings) {
        const settingsJson = JSON.parse(settings);

        attachErrorHandler(photo, settingsJson);

        if (settingsJson.hasLightbox) this.getLightBox().photoModalData[widgetId] = settingsJson;

        this.connectPhotoToLightBox(widgetId);
      }
    });

    this.lazyLoadImages();
  };

  lazyLoadImages = () => {
    const imgCollection = dom.getCollection('img.lazy');
    let imageCount = 0;
    let preventCheck = false;
    const verticalLazyViewport = dom.window.innerHeight * 1.5;

    if (isEmpty(imgCollection)) return;

    const loadNextImage = () => {
      if (imageCount > imgCollection.length - 1 || preventCheck) return;

      const currentImage = imgCollection[imageCount];
      const currentImageOffset = currentImage.getBoundingClientRect().top;

      if (currentImageOffset > verticalLazyViewport) {
        preventCheck = true;
        lazyLoad();

        return;
      }

      const dataSrc = currentImage.getAttribute('data-src');

      const img = new Image();

      img.onload = () => {
        currentImage.setAttribute('src', dataSrc);
        imageCount += 1;

        loadNextImage();
      };
      img.src = dataSrc;
    };

    loadNextImage();
  }

  getLightBox() {
    if (!this.lightBox) this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);

    return this.lightBox;
  }

  connectPhotoToLightBox = (photoId) => {
    const lightBox = this.getLightBox();
    const { modalsParams } = lightBox.getLightBoxAttributes();

    const photoToConnect = photoId
      ? { [photoId]: lightBox.photoModalData[photoId] }
      : lightBox.photoModalData;

    lightBox.addLightBoxToPhotos(
      photoToConnect,
      modalsParams
    );
  };
}

export default PostImage;
