import get from 'lodash/get';
import isNil from 'lodash/isNil';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import checkIsGdprEnabled from '../../helpers/checkIsGdprEnabled';
import initAnalytics from '../Analytics';

import CookieModal from './CookieModal';
import CookieAnalyticsManager from './CookieAnalyticsManager';
import CookieModalLink from './CookieModalLink';
import CookieNotice from './CookieNotice';
import { getUserLocation, setCookiesPageLink } from './utils';

import {
  COOKIE_MODAL,
  COOKIE_NOTICE_TOOLBAR,
  COOKIE_MODAL_HREF,
} from './constants';

class CookieBannerManager extends WidgetWrapper {
  init = async () => {
    this.elCookieBanner = dom.getElement(this.selector);

    if (!this.elCookieBanner) return;

    const elCookieModal = dom.getElement(COOKIE_MODAL);

    if (!elCookieModal) return;

    const isGdprEnabled = await checkIsGdprEnabled();

    if (!isGdprEnabled) {
      dom.removeElement(this.elCookieBanner);
      dom.removeElement(elCookieModal);
      return;
    }

    this.cookieAnalyticsManager = new CookieAnalyticsManager();
    this.cookieModal = new CookieModal(COOKIE_MODAL, this);
    this.cookieModal.init();

    this.cookieNotice = new CookieNotice(COOKIE_NOTICE_TOOLBAR, this);
    this.cookieNotice.init();

    setCookiesPageLink();

    this.cookieModalLink = new CookieModalLink(COOKIE_MODAL_HREF, this);
    this.cookieModalLink.init();

    this.handleGeoipData();
  };

  handleGeoipData = () => {
    getUserLocation()
      .then(
        (data) => {
          const geoLocation = get(data, ['country_code'], null);
          this.cookieAnalyticsManager.updateGeoLocation(geoLocation);
        },
      )
      .finally(() => this.handleCookieBanner());
  }

  handleCookieBanner = async () => {
    this.cookieAnalyticsManager.updateCountry();
    const isCookieAlreadyExists = this.cookieAnalyticsManager.checkIsAlreadyHandled();

    if (isCookieAlreadyExists) return;

    const isEuro = await this.cookieAnalyticsManager.checkIsEurope();
    const isBannerEnabled = this.cookieNotice.checkIsEnabled();

    if (!isBannerEnabled && !isEuro) return;

    this.cookieNotice.show();
  }

  showModal = (event) => {
    event.preventDefault();
    this.cookieModal.show();
  }

  getAnalyticsInitial = async () => {
    const initialValue = await this.cookieAnalyticsManager.getInitialValue();

    return initialValue;
  }

  accept = async (value) => {
    const valueToSave = isNil(value) ? await this.getAnalyticsInitial() : value;
    this.cookieAnalyticsManager.saveSettingsToCookies(valueToSave);
    this.cookieNotice.hide();

    if (valueToSave) {
      initAnalytics();
    } else {
      this.cookieAnalyticsManager.removeAnalytics();
    }
  }
}

export default CookieBannerManager;
