import isElement from 'lodash/isElement';

import { FOOTER_SELECTOR } from '../constants';

/**
 * Util for check if footer section is parent for node element
 * @param {Node} elWrapper
 * @return {boolean} isFooterParent
 */
const checkFooterParent = (elWrapper) => isElement(elWrapper) && !!elWrapper.closest(FOOTER_SELECTOR);

export default checkFooterParent;
