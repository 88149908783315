import format from 'date-fns/format';

/** Util for convert sql date string to date-fns format
 * @param {string} date (2021-12-15 10:33:04, 2021-12-15T10:33:04.902859Z)
 * @param {string} custom
 * @return {string} (15 Dec 20201)
 */
// eslint-disable-next-line import/prefer-default-export
export function toDate(date, custom) {
  if (!date) return format(new Date(), custom);

  const [data, time] = date.split(' ');
  const validDate = !!data && !!time ? `${data}T${time}` : date; // convert to iso format
  const dateObj = new Date(validDate);

  return format(dateObj, custom);
}
