import isNil from 'lodash/isNil';
import isElement from 'lodash/isElement';

import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import { DARK_ICON_CLASSNAME, SOC_ICON_SELECTOR } from './constants';
import { isLight } from '../../helpers/color';

class SocialButtons extends WidgetWrapper {
    init = () => {
      const widgets = dom.getCollection(this.selector);

      if (isNil(widgets)) return;

      widgets.forEach((elWidget) => {
        this.initWidget(elWidget);
      });
    }

    initWidget = (elWidget) => {
      if (!isElement(elWidget)) return;

      const icons = dom.getCollection(SOC_ICON_SELECTOR, elWidget);

      if (isNil(icons)) return;

      icons.forEach((elIcon) => {
        const iconColor = dom.window.getComputedStyle(elIcon).color || '#000';

        if (isLight(iconColor)) dom.addClass(elIcon, DARK_ICON_CLASSNAME);
      });
    }
}

export default SocialButtons;
