// eslint-disable-next-line import/prefer-default-export
export const LIGHT_BOX_SELECTOR = {
  modalContainerId: 'gallery-bp_container',
  lightBoxImgId: 'gallery-light-box__img',
  nextBottomId: 'gallery-light-box__arrow_right',
  prevBottomId: 'gallery-light-box__arrow_left',
  navBotContPrevId: 'gallery-light-box__arrow_left_container',
  navBotContNextId: 'gallery-light-box__arrow_right_container',
  svgBottomPrevId: 'gallery-light-box__arrow_left_svg',
  svgBottomNextId: 'gallery-light-box__arrow_right_svg',
};

export const GALLERY_CLONED = 'gallery-cloned';
export const GALLERY_CONTAINER_SELECTOR = (hash) => `.js-widget[data-widget="gallery"][data-widget-id="${hash}"]:not(.${GALLERY_CLONED})`;
export const GALLERY_CLONED_CONTAINER_SELECTOR = (hash, clonedIndex) => `.js-widget.${GALLERY_CLONED}-${clonedIndex}[data-widget="gallery"][data-widget-id="${hash}"]`;
