import isEmpty from 'lodash/isEmpty';
import isElement from 'lodash/isElement';

import dom from '../../../wrapper/DomWrapper';
import checkFooterParent from './checkFooterParent';
import { SSL_SEAL_SELECTOR } from '../../../constants';
import { FOOTER_SELECTOR } from '../constants';

/**
 * Util for check on disabled ssl widget
 * @param {Node} elWrapper
 * @return {boolean} isDisabledSSL
 */
const checkDisabledSSL = (elWrapper) => {
  const elFooter = dom.getElement(FOOTER_SELECTOR);

  if (!isElement(elWrapper) || !isElement(elFooter)) return false;

  const isFooterParent = checkFooterParent(elWrapper);

  if (isFooterParent) return false; // ssl widget in footer structure can not be disabled

  const elements = dom.getCollection(SSL_SEAL_SELECTOR, elFooter);

  return !isEmpty(elements);
};

export default checkDisabledSSL;
