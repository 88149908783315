import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';

// TODO set to structure link href instead
export default () => {
  const pageLinks = dom.getCollection('a');

  if (isEmpty(pageLinks)) return [];

  const cookiesLinkText = 'manage the cookies';

  Array.from(pageLinks).forEach((link) => {
    if (link.textContent === cookiesLinkText) {
      // eslint-disable-next-line no-param-reassign
      link.href = '#cookie-notice-popup';
    }
  });
};
