import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import dom from '../../../wrapper/DomWrapper';
import cleanPathName from './cleanPathName';

/**
 * Util for check if href pathName is equal to current page pathName
 * @param {string} href
 * @return {boolean} isHrefHasSamePathName
 */
export default (href) => {
  if (!isString(href) || isEmpty(href)) return false;

  const { origin: currentOrigin, pathname: currentPathName } = dom.window.location;

  try {
    const currentPageUrl = new URL(currentOrigin);
    const anchorUrl = new URL(href, currentPageUrl);
    const { pathname: anchorPathName } = anchorUrl;

    return cleanPathName(currentPathName) === cleanPathName(anchorPathName);
  } catch (e) {
    console.error(e);
  }
};
